const format_grid = (grid_string) =>
  grid_string
    // create an array of rows as strings
    .replaceAll(' ', '').split('\n')
    // create an array of cells as boolean values for each row
    .map((row_string) =>
      row_string.split('').map((character) => character === '■'),
    )


export const shape_1 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · ■ ■ · · · · · · · ■ ■ · · · · · · · ■ ■ · · ·
  · · · ■ ■ ■ · · · · · ■ ■ ■ ■ · · · · · ■ ■ ■ · · ·
  · · · · ■ ■ ■ · · · · ■ · · ■ · · · · ■ ■ ■ · · · ·
  · · · · · ■ ■ ■ ■ · ■ ■ · · ■ ■ · ■ ■ ■ ■ · · · · ·
  · · · · · · ■ · ■ ■ ■ · · · · ■ ■ ■ · ■ · · · · · ·
  · · · · · · ■ ■ · ■ ■ · ■ ■ · ■ ■ · ■ ■ · · · · · ·
  · · · · · · · ■ ■ ■ · · · · · · ■ ■ ■ · · · · · · ·
  · · · · · · ■ ■ ■ · · ■ · · ■ · · ■ ■ ■ · · · · · ·
  · · · · ■ ■ ■ · · · ■ ■ · · ■ ■ · · · ■ ■ ■ · · · ·
  · · · ■ ■ · · · ■ · · · ■ ■ · · · ■ · · · ■ ■ · · ·
  · · · ■ ■ · · · ■ · · · ■ ■ · · · ■ · · · ■ ■ · · ·
  · · · · ■ ■ ■ · · · ■ ■ · · ■ ■ · · · ■ ■ ■ · · · ·
  · · · · · · ■ ■ ■ · · ■ · · ■ · · ■ ■ ■ · · · · · ·
  · · · · · · · ■ ■ ■ · · · · · · ■ ■ ■ · · · · · · ·
  · · · · · · ■ ■ · ■ ■ · ■ ■ · ■ ■ · ■ ■ · · · · · ·
  · · · · · · ■ · ■ ■ ■ · · · · ■ ■ ■ · ■ · · · · · ·
  · · · · · ■ ■ ■ ■ · ■ ■ · · ■ ■ · ■ ■ ■ ■ · · · · ·
  · · · · ■ ■ ■ · · · · ■ · · ■ · · · · ■ ■ ■ · · · ·
  · · · ■ ■ ■ · · · · · ■ ■ ■ ■ · · · · · ■ ■ ■ · · ·
  · · · ■ ■ · · · · · · · ■ ■ · · · · · · · ■ ■ · · ·
  · · · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · · · ·`
)

export const shape_2 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · ■ ■ ■ ■ · · · · · · · · · ·
  · · · · · · · · · ■ ■ · · ■ ■ · · · · · · · · ·
  · · · · · ■ ■ ■ ■ ■ · · · · ■ ■ ■ ■ ■ · · · · ·
  · · · · ■ ■ · · · ■ · · · · ■ · · · ■ ■ · · · ·
  · · · · ■ · · · · · ■ ■ ■ ■ · · · · · ■ · · · ·
  · · · · ■ · · · ■ ■ ■ · · ■ ■ ■ · · · ■ · · · ·
  · · · · ■ · · ■ ■ · ■ · · ■ · ■ ■ · · ■ · · · ·
  · · · ■ ■ ■ · ■ · · · · · · · · ■ · ■ ■ ■ · · ·
  · · ■ ■ · · ■ ■ ■ · · ■ ■ · · ■ ■ ■ · · ■ ■ · ·
  · · ■ · · · ■ · · · ■ · · ■ · · · ■ · · · ■ · ·
  · · ■ · · · ■ · · · ■ · · ■ · · · ■ · · · ■ · ·
  · · ■ ■ · · ■ ■ ■ · · ■ ■ · · ■ ■ ■ · · ■ ■ · ·
  · · · ■ ■ ■ · ■ · · · · · · · · ■ · ■ ■ ■ · · ·
  · · · · ■ · · ■ ■ · ■ · · ■ · ■ ■ · · ■ · · · ·
  · · · · ■ · · · ■ ■ ■ · · ■ ■ ■ · · · ■ · · · ·
  · · · · ■ · · · · · ■ ■ ■ ■ · · · · · ■ · · · ·
  · · · · ■ ■ · · · ■ · · · · ■ · · · ■ ■ · · · ·
  · · · · · ■ ■ ■ ■ ■ · · · · ■ ■ ■ ■ ■ · · · · ·
  · · · · · · · · · ■ ■ · · ■ ■ · · · · · · · · ·
  · · · · · · · · · · ■ ■ ■ ■ · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · ·`
)

export const shape_3 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · ■ ■ ■ ■ · · · · · · · · · ·
  · · · · · · · · · ■ ■ ■ ■ ■ ■ · · · · · · · · ·
  · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · ·
  · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · ·
  · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · ·
  · · · · ■ ■ ■ ■ ■ ■ ■ · · ■ ■ ■ ■ ■ ■ ■ · · · ·
  · · · · ■ ■ ■ ■ ■ · ■ · · ■ · ■ ■ ■ ■ ■ · · · ·
  · · · ■ ■ ■ ■ ■ · · · · · · · · ■ ■ ■ ■ ■ · · ·
  · · ■ ■ ■ ■ ■ ■ ■ · · ■ ■ · · ■ ■ ■ ■ ■ ■ ■ · ·
  · · ■ ■ ■ ■ ■ · · · ■ ■ ■ ■ · · · ■ ■ ■ ■ ■ · ·
  · · ■ ■ ■ ■ ■ · · · ■ ■ ■ ■ · · · ■ ■ ■ ■ ■ · ·
  · · ■ ■ ■ ■ ■ ■ ■ · · ■ ■ · · ■ ■ ■ ■ ■ ■ ■ · ·
  · · · ■ ■ ■ ■ ■ · · · · · · · · ■ ■ ■ ■ ■ · · ·
  · · · · ■ ■ ■ ■ ■ · ■ · · ■ · ■ ■ ■ ■ ■ · · · ·
  · · · · ■ ■ ■ ■ ■ ■ ■ · · ■ ■ ■ ■ ■ ■ ■ · · · ·
  · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · ·
  · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · ·
  · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · ·
  · · · · · · · · · ■ ■ ■ ■ ■ ■ · · · · · · · · ·
  · · · · · · · · · · ■ ■ ■ ■ · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · ·`
)

export const shape_4 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · ■ ■ ■ ■ · · · · · · · · ·
  · · · · · · · · ■ ■ · · ■ ■ · · · · · · · ·
  · · · · ■ ■ ■ ■ ■ · · · · ■ ■ ■ ■ ■ · · · ·
  · · · · ■ · · · · · ■ ■ · · · · · ■ · · · ·
  · · · · ■ · ■ ■ · · · · · · ■ ■ · ■ · · · ·
  · · · · ■ · ■ · · · · · · · · ■ · ■ · · · ·
  · · · ■ ■ · · · · · · · · · · · · ■ ■ · · ·
  · · ■ ■ · · · · · · ■ ■ · · · · · · ■ ■ · ·
  · · ■ · · ■ · · · ■ · · ■ · · · ■ · · ■ · ·
  · · ■ · · ■ · · · ■ · · ■ · · · ■ · · ■ · ·
  · · ■ ■ · · · · · · ■ ■ · · · · · · ■ ■ · ·
  · · · ■ ■ · · · · · · · · · · · · ■ ■ · · ·
  · · · · ■ · ■ · · · · · · · · ■ · ■ · · · ·
  · · · · ■ · ■ ■ · · · · · · ■ ■ · ■ · · · ·
  · · · · ■ · · · · · ■ ■ · · · · · ■ · · · ·
  · · · · ■ ■ ■ ■ ■ · · · · ■ ■ ■ ■ ■ · · · ·
  · · · · · · · · ■ ■ · · ■ ■ · · · · · · · ·
  · · · · · · · · · ■ ■ ■ ■ · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·`
)

export const shape_5 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · ■ ■ ■ ■ · · · · · · · · ·
  · · · · · · · · ■ ■ · · ■ ■ · · · · · · · ·
  · · · · · · · ■ ■ · · · · ■ ■ · · · · · · ·
  · · · · · · · ■ · · ■ ■ · · ■ · · · · · · ·
  · · · · · ■ ■ ■ · · · · · · ■ ■ ■ · · · · ·
  · · · · ■ ■ · · · · · · · · · · ■ ■ · · · ·
  · · · ■ ■ · · · · ■ · · ■ · · · · ■ ■ · · ·
  · · · ■ · · ■ · · · ■ ■ · · · ■ · · ■ · · ·
  · · · ■ · · ■ · · · ■ ■ · · · ■ · · ■ · · ·
  · · · ■ ■ · · · · ■ · · ■ · · · · ■ ■ · · ·
  · · · · ■ ■ · · · · · · · · · · ■ ■ · · · ·
  · · · · · ■ ■ ■ · · · · · · ■ ■ ■ · · · · ·
  · · · · · · · ■ · · ■ ■ · · ■ · · · · · · ·
  · · · · · · · ■ ■ · · · · ■ ■ · · · · · · ·
  · · · · · · · · ■ ■ · · ■ ■ · · · · · · · ·
  · · · · · · · · · ■ ■ ■ ■ · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·`
)

export const shape_6 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · · ·
  · · · · · · · · · ■ ■ ■ ■ · · · · · · · · ·
  · · · ■ ■ · · · · ■ · · ■ · · · · ■ ■ · · ·
  · · · ■ ■ ■ ■ ■ · ■ · · ■ · ■ ■ ■ ■ ■ · · ·
  · · · · ■ ■ · ■ ■ ■ · · ■ ■ ■ · ■ ■ · · · ·
  · · · · · ■ ■ · · · · · · · · ■ ■ · · · · ·
  · · · · · · ■ ■ · · ■ ■ · · ■ ■ · · · · · ·
  · · · · · · ■ ■ · · ■ ■ · · ■ ■ · · · · · ·
  · · · · · ■ ■ · · · · · · · · ■ ■ · · · · ·
  · · · · ■ ■ · ■ ■ ■ · · ■ ■ ■ · ■ ■ · · · ·
  · · · ■ ■ ■ ■ ■ · ■ · · ■ · ■ ■ ■ ■ ■ · · ·
  · · · ■ ■ · · · · ■ · · ■ · · · · ■ ■ · · ·
  · · · · · · · · · ■ ■ ■ ■ · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·`
)

export const shape_7 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · · ·
  · · · · · · · · · ■ · · ■ · · · · · · · · ·
  · · · ■ ■ ■ ■ · · ■ · · ■ · · ■ ■ ■ ■ · · ·
  · · · ■ · · ■ ■ · ■ · · ■ · ■ ■ · · ■ · · ·
  · · · ■ · · · ■ ■ ■ · · ■ ■ ■ · · · ■ · · ·
  · · · ■ ■ · · · · ■ · · ■ · · · · ■ ■ · · ·
  · · · · ■ ■ · · · ■ · · ■ · · · ■ ■ · · · ·
  · · · · · ■ · · · ■ · · ■ · · · ■ · · · · ·
  · · ■ ■ ■ ■ ■ ■ ■ ■ · · ■ ■ ■ ■ ■ ■ ■ ■ · ·
  · ■ · · · · · · · · · · · · · · · · · · ■ ·
  · ■ · · · · · · · · · · · · · · · · · · ■ ·
  · · ■ ■ ■ ■ ■ ■ ■ ■ · · ■ ■ ■ ■ ■ ■ ■ ■ · ·
  · · · · · ■ · · · ■ · · ■ · · · ■ · · · · ·
  · · · · ■ ■ · · · ■ · · ■ · · · ■ ■ · · · ·
  · · · ■ ■ · · · · ■ · · ■ · · · · ■ ■ · · ·
  · · · ■ · · · ■ ■ ■ · · ■ ■ ■ · · · ■ · · ·
  · · · ■ · · ■ ■ · ■ · · ■ · ■ ■ · · ■ · · ·
  · · · ■ ■ ■ ■ · · ■ · · ■ · · ■ ■ ■ ■ · · ·
  · · · · · · · · · ■ · · ■ · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·`
)

export const shape_8 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · ·
  · ■ · · · · · · · ■ ■ ■ ■ · · · · · · · ■ ·
  · · ■ ■ · · · · ■ ■ · · ■ ■ · · · · ■ ■ · ·
  · · ■ · · · · · ■ · · · · ■ · · · · · ■ · ·
  · · · · · ■ ■ ■ ■ · · · · ■ ■ ■ ■ · · · · ·
  · · · · · ■ · · · · · · · · · · ■ · · · · ·
  · · · · ■ ■ · · · · ■ ■ · · · · ■ ■ · · · ·
  · · · · ■ · · · ■ ■ ■ ■ ■ ■ · · · ■ · · · ·
  · · ■ ■ ■ · · ■ ■ · · · · ■ ■ · · ■ ■ ■ · ·
  · ■ ■ · · · · ■ ■ · · · · ■ ■ · · · · ■ ■ ·
  · ■ · · · · ■ ■ · · ■ ■ · · ■ ■ · · · · ■ ·
  · ■ · · · · ■ ■ · · ■ ■ · · ■ ■ · · · · ■ ·
  · ■ ■ · · · · ■ ■ · · · · ■ ■ · · · · ■ ■ ·
  · · ■ ■ ■ · · ■ ■ · · · · ■ ■ · · ■ ■ ■ · ·
  · · · · ■ · · · ■ ■ ■ ■ ■ ■ · · · ■ · · · ·
  · · · · ■ ■ · · · · ■ ■ · · · · ■ ■ · · · ·
  · · · · · ■ · · · · · · · · · · ■ · · · · ·
  · · · · · ■ ■ ■ ■ · · · · ■ ■ ■ ■ · · · · ·
  · · ■ · · · · · ■ · · · · ■ · · · · · ■ · ·
  · · ■ ■ · · · · ■ ■ · · ■ ■ · · · · ■ ■ · ·
  · ■ · · · · · · · ■ ■ ■ ■ · · · · · · · ■ ·
  · · · · · · · · · · · · · · · · · · · · · ·`
)

export const shape_9 = format_grid(
//1 2 3 4 5 6 7 8 9 0 1 2 3 4 5 6 7 8 9 0 1 2 3 4 5 6
` · · · · · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · ■ ■ · · · · · · ■ ■ ■ ■ · · · · · · ■ ■ · · · ·
  · · · · ■ ■ ■ · · · · ■ ■ · · ■ ■ · · · · ■ ■ ■ · · · ·
  · · · · · ■ ■ ■ · · · ■ · · · · ■ · · · ■ ■ ■ · · · · ·
  · · · · · · ■ ■ ■ ■ · ■ · · · · ■ · ■ ■ ■ ■ · · · · · ·
  · · · · · · · ■ · ■ ■ ■ · · · · ■ ■ ■ · ■ · · · · · · ·
  · · · · · · · ■ ■ · · ■ · · · · ■ · · ■ ■ · · · · · · ·
  · · · · · · · · ■ · · ■ · · · · ■ · · ■ · · · · · · · ·
  · · · · · ■ ■ ■ ■ ■ ■ ■ · · · · ■ ■ ■ ■ ■ ■ ■ · · · · ·
  · · · · ■ ■ · · · · · · · · · · · · · · · · ■ ■ · · · ·
  · · · · ■ · · · · · · · · · · · · · · · · · · ■ · · · ·
  · · · · ■ · · · · · · · · · · · · · · · · · · ■ · · · ·
  · · · · ■ ■ · · · · · · · · · · · · · · · · ■ ■ · · · ·
  · · · · · ■ ■ ■ ■ ■ ■ ■ · · · · ■ ■ ■ ■ ■ ■ ■ · · · · ·
  · · · · · · · · ■ · · ■ · · · · ■ · · ■ · · · · · · · ·
  · · · · · · · ■ ■ · · ■ · · · · ■ · · ■ ■ · · · · · · ·
  · · · · · · · ■ · ■ ■ ■ · · · · ■ ■ ■ · ■ · · · · · · ·
  · · · · · · ■ ■ ■ ■ · ■ · · · · ■ · ■ ■ ■ ■ · · · · · ·
  · · · · · ■ ■ ■ · · · ■ · · · · ■ · · · ■ ■ ■ · · · · ·
  · · · · ■ ■ ■ · · · · ■ ■ · · ■ ■ · · · · ■ ■ ■ · · · ·
  · · · · ■ ■ · · · · · · ■ ■ ■ ■ · · · · · · ■ ■ · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · · · · · · ·`
)

export const shape_10 = format_grid(
//1 2 3 4 5 6 7 8 9 0 1 2 3 4 5 6 7 8 9 0 1 2 3
` ■ · · · · · · · · · · ■ · · · · · · · · · · ■
  · ■ · · · · · · · · · · · · · · · · · · · ■ ·
  · · ■ · · ■ ■ ■ ■ ■ ■ · ■ ■ ■ ■ ■ ■ · · ■ · ·
  · · · ■ ■ ■ · · · · ■ ■ ■ · · · · ■ ■ ■ · · ·
  · · · ■ ■ · · · · · · · · · · · · · ■ ■ · · ·
  · · ■ ■ · ■ · · · · · · · · · · · ■ · ■ ■ · ·
  · · ■ · · · ■ ■ ■ ■ ■ · ■ ■ ■ ■ ■ · · · ■ · ·
  · · ■ · · · ■ ■ · · · ■ · · · ■ ■ · · · ■ · ·
  · · ■ · · · ■ · ■ · · · · · ■ · ■ · · · ■ · ·
  · · ■ · · · ■ · · ■ · · · ■ · · ■ · · · ■ · ·
  · · ■ ■ · · ■ · · · ■ · ■ · · · ■ · · ■ ■ · ·
  ■ · · ■ · · · ■ · · · ■ · · · ■ · · · ■ · · ■
  · · ■ ■ · · ■ · · · ■ · ■ · · · ■ · · ■ ■ · ·
  · · ■ · · · ■ · · ■ · · · ■ · · ■ · · · ■ · ·
  · · ■ · · · ■ · ■ · · · · · ■ · ■ · · · ■ · ·
  · · ■ · · · ■ ■ · · · ■ · · · ■ ■ · · · ■ · ·
  · · ■ · · · ■ ■ ■ ■ ■ · ■ ■ ■ ■ ■ · · · ■ · ·
  · · ■ ■ · ■ · · · · · · · · · · · ■ · ■ ■ · ·
  · · · ■ ■ · · · · · · · · · · · · · ■ ■ · · ·
  · · · ■ ■ ■ · · · · ■ ■ ■ · · · · ■ ■ ■ · · ·
  · · ■ · · ■ ■ ■ ■ ■ ■ · ■ ■ ■ ■ ■ ■ · · ■ · ·
  · ■ · · · · · · · · · · · · · · · · · · · ■ ·
  ■ · · · · · · · · · · ■ · · · · · · · · · · ■`
)

  

export const character_0 = format_grid(
` · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · ■ ■ ■ ■ ■ ■ ■ · · · · · · ·
  · · · · · · · ■ ■ ■ ■ ■ ■ ■ · · · · · · ·
  · · · · · ■ ■ · · · · · · · ■ ■ · · · · ·
  · · · · · ■ ■ · · · · · · · ■ ■ · · · · ·
  · · · · · ■ ■ · · · · · · · ■ ■ · · · · ·
  · · · · · ■ ■ · · · · · · · ■ ■ · · · · ·
  · · · · · ■ ■ · · · ■ · · · ■ ■ · · · · ·
  · · · · · ■ ■ · · ■ ■ ■ · · ■ ■ · · · · ·
  · · · · · ■ ■ · · · ■ · · · ■ ■ · · · · ·
  · · · · · ■ ■ · · · · · · · ■ ■ · · · · ·
  · · · · · ■ ■ · · · · · · · ■ ■ · · · · ·
  · · · · · ■ ■ · · · · · · · ■ ■ · · · · ·
  · · · · · ■ ■ · · · · · · · ■ ■ · · · · ·
  · · · · · · · ■ ■ ■ ■ ■ ■ ■ · · · · · · ·
  · · · · · · · ■ ■ ■ ■ ■ ■ ■ · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · ·`
)

export const character_1 = format_grid(
` · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · ·
  · · · · · · · · · ■ ■ ■ · · · · · · · · ·
  · · · · · · · · ■ ■ ■ ■ · · · · · · · · ·
  · · · · · · · ■ ■ ■ ■ ■ · · · · · · · · ·
  · · · · · · · ■ ■ · ■ ■ · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · ·
  · · · · · · · · · · ■ ■ · · · · · · · · ·
  · · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ · · · · · ·
  · · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ · · · · · ·
  · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · ·`
)

export const character_2 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · ·
  · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · ·
  · · · ■ ■ · · · · · · · · · · · · ■ ■ · · ·
  · · · ■ ■ · · · · · · · · · · · · ■ ■ · · ·
  · · · · · · · · · · · · · · · · · ■ ■ · · ·
  · · · · · · · · · · · · · · · · · ■ ■ · · ·
  · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · ·
  · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · ·
  · · · ■ ■ · · · · · · · · · · · · · · · · ·
  · · · ■ ■ · · · · · · · · · · · · · · · · ·
  · · · ■ ■ · · · · · · · · · · · · · · · · ·
  · · · ■ ■ · · · · · · · · · · · · · · · · ·
  · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · ·
  · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·`
)

export const character_3 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · · ·
  · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · · ·
  · · · · · · · · · · · · · · ■ ■ · · · · · ·
  · · · · · · · · · · · · · · ■ ■ · · · · · ·
  · · · · · · · · · · · · ■ ■ · · · · · · · ·
  · · · · · · · · · · · · ■ ■ · · · · · · · ·
  · · · · · · · · · · ■ ■ ■ ■ · · · · · · · ·
  · · · · · · · · · · ■ ■ ■ ■ · · · · · · · ·
  · · · · · · · · · · · · · · ■ ■ · · · · · ·
  · · · · · · · · · · · · · · ■ ■ · · · · · ·
  · · · · · · ■ ■ · · · · · · ■ ■ · · · · · ·
  · · · · · · ■ ■ · · · · · · ■ ■ · · · · · ·
  · · · · · · · · ■ ■ ■ ■ ■ ■ · · · · · · · ·
  · · · · · · · · ■ ■ ■ ■ ■ ■ · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·`
)

export const character_5 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · · ·
  · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · · ·
  · · · · · · ■ ■ ■ · · · · · · · · · · · · ·
  · · · · · · ■ ■ ■ · · · · · · · · · · · · ·
  · · · · · · ■ ■ ■ · · · · · · · · · · · · ·
  · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ · · · · · · · ·
  · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · · ·
  · · · · · · ■ ■ ■ · · · ■ ■ ■ ■ · · · · · ·
  · · · · · · · · · · · · ■ ■ ■ ■ · · · · · ·
  · · · · · · · · · · · · ■ ■ ■ ■ · · · · · ·
  · · · · · · ■ ■ ■ ■ · · ■ ■ ■ ■ · · · · · ·
  · · · · · · ■ ■ ■ ■ · · ■ ■ ■ ■ · · · · · ·
  · · · · · · · · ■ ■ ■ ■ ■ ■ · · · · · · · ·
  · · · · · · · · ■ ■ ■ ■ ■ ■ · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·`
)

export const character_6 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ · · · · · · ·
  · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · · ·
  · · · · · ■ ■ ■ · · · · · · ■ ■ ■ · · · · ·
  · · · · · ■ ■ · · · · · · · · ■ ■ · · · · ·
  · · · · · ■ ■ · · · · · · · · · · · · · · ·
  · · · · · ■ ■ · · · · · · · · · · · · · · ·
  · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · · · ·
  · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · · ·
  · · · · · ■ ■ ■ · · · · · · ■ ■ ■ · · · · ·
  · · · · · ■ ■ · · · · · · · · ■ ■ · · · · ·
  · · · · · ■ ■ · · · · · · · · ■ ■ · · · · ·
  · · · · · ■ ■ ■ · · · · · · ■ ■ ■ · · · · ·
  · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · · · ·
  · · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·`
)

export const character_7 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · · 
  · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · 
  · · · ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ · · · 
  · · · · · · · · · · · · · · · · · ■ ■ · · · 
  · · · · · · · · · · · · · · · · · ■ ■ · · · 
  · · · · · · · · · · · · · · · ■ ■ · · · · · 
  · · · · · · · · · · · · · · · ■ ■ · · · · · 
  · · · · · · · · · · · · · ■ ■ · · · · · · · 
  · · · · · · · · · · · · · ■ ■ · · · · · · · 
  · · · · · · · · · · · ■ ■ · · · · · · · · · 
  · · · · · · · · · · · ■ ■ · · · · · · · · · 
  · · · · · · · · · ■ ■ · · · · · · · · · · · 
  · · · · · · · · · ■ ■ · · · · · · · · · · · 
  · · · · · · · ■ ■ · · · · · · · · · · · · · 
  · · · · · · · ■ ■ · · · · · · · · · · · · · 
  · · · · · · · ■ ■ · · · · · · · · · · · · · 
  · · · · · · · ■ ■ · · · · · · · · · · · · · 
  · · · · · · · · · · · · · · · · · · · · · · 
  · · · · · · · · · · · · · · · · · · · · · · 
  · · · · · · · · · · · · · · · · · · · · · ·`
)

export const character_8 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · ■ ■ ■ ■ ■ ■ · · · · · · · ·
  · · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ · · · · · · ·
  · · · · · · ■ ■ · · · · · · ■ ■ · · · · · ·
  · · · · · · ■ ■ · · · · · · ■ ■ · · · · · ·
  · · · · · · ■ ■ · · · · · · ■ ■ · · · · · ·
  · · · · · · ■ ■ · · · · · · ■ ■ · · · · · ·
  · · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ · · · · · · ·
  · · · · · · · · ■ ■ ■ ■ ■ ■ · · · · · · · ·
  · · · · · · ■ ■ · · · · · · ■ ■ · · · · · ·
  · · · · · · ■ ■ · · · · · · ■ ■ · · · · · ·
  · · · · · · ■ ■ · · · · · · ■ ■ · · · · · ·
  · · · · · · ■ ■ · · · · · · ■ ■ · · · · · ·
  · · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ · · · · · · ·
  · · · · · · · · ■ ■ ■ ■ ■ ■ · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·`
)

export const character_9 = format_grid(
` · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · ■ ■ ■ ■ ■ ■ · · · · · · · ·
  · · · · · · · · ■ ■ ■ ■ ■ ■ · · · · · · · ·
  · · · · · · ■ ■ ■ ■ · · ■ ■ ■ ■ · · · · · ·
  · · · · · · ■ ■ ■ ■ · · ■ ■ ■ ■ · · · · · ·
  · · · · · · ■ ■ ■ ■ · · ■ ■ ■ ■ · · · · · ·
  · · · · · · ■ ■ ■ ■ · · ■ ■ ■ ■ · · · · · ·
  · · · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ · · · · · ·
  · · · · · · · · ■ ■ ■ ■ ■ ■ ■ ■ · · · · · ·
  · · · · · · · · · · · · ■ ■ ■ ■ · · · · · ·
  · · · · · · · · · · · · ■ ■ ■ ■ · · · · · ·
  · · · · · · ■ ■ ■ ■ · · ■ ■ ■ ■ · · · · · ·
  · · · · · · ■ ■ ■ ■ · · ■ ■ ■ ■ · · · · · ·
  · · · · · · · · ■ ■ ■ ■ ■ ■ · · · · · · · ·
  · · · · · · · · ■ ■ ■ ■ ■ ■ · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·
  · · · · · · · · · · · · · · · · · · · · · ·`
)
