import { useState, useEffect } from 'react'
import { random } from '../utils/toolbox'
import { Component } from '../utils/flags'
import { Select } from '../utils/components'

const walks_amount = 10
const moves_amount = 100000

export const Block_90 = ({ id }) => {
  const [canvas, set_canvas] = useState(null)
  const [context, set_context] = useState(null)
  const [colors_amount, set_colors_amount] = useState(2)
  const [rect_size, set_rect_size] = useState(1)
  const [colors, set_colors] = useState(get_random_colors())
  const [pixels, set_pixels] = useState([])

  const generate_random_walks = (context) => {
    const { width, height } = canvas?.getBoundingClientRect()
    canvas.width = width
    canvas.height = height

    const pixels = []

    for (let walk = 0; walk < walks_amount; walk++) {
      let x = width / 2
      let y = height / 2

      for (let move = 0; move < moves_amount; move++) {
        x += random(-1, 1)
        y += random(-1, 1)
        pixels.push({ x, y })
      }
    }

    set_pixels(pixels)
  }

  const draw_walks = (context) => {
    context.clearRect(0, 0, canvas.width, canvas.height)
    pixels.forEach((pixel, index) => {
      const { x, y } = pixel
      context.fillStyle = colors[((index / moves_amount) % colors_amount) + 1]
      context.fillRect(
        x - rect_size / 2,
        y - rect_size / 2,
        rect_size,
        rect_size,
      )
    })
  }

  useEffect(() => {
    if (!canvas) return
    const context = canvas.getContext('2d')
    set_context(context)
    generate_random_walks(context)
  }, [canvas])

  useEffect(() => {
    if (!context) return
    draw_walks(context)
  }, [context, pixels, colors, colors_amount, rect_size])

  const select_options = [...Array(10).keys()].map((index) => ({
    label: `${index + 1} color${index ? 's' : ''}`,
    value: index + 1,
  }))

  const select_options_2 = [...Array(40).keys()].map((index) => ({
    label: `${index + 1} pixel${index ? 's' : ''}`,
    value: index + 1,
  }))

  console.log(colors)

  return (
    <Wrapper style={{ background: colors[0] }}>
      <Canvas elemRef={set_canvas} />
      <Controls style={{ gap: '15px' }}>
        <Button onClick={() => generate_random_walks(context)}>
          New random walk
        </Button>

        <Select
          w150
          bg_white
          list={select_options}
          value={colors_amount}
          set_value={set_colors_amount}
          wrapper_id={id}
        />

        <Select
          w150
          bg_white
          list={select_options_2}
          value={rect_size}
          set_value={set_rect_size}
          wrapper_id={id}
        />

        <Button onClick={() => set_colors(get_random_colors())}>
          Change colors
        </Button>
      </Controls>
    </Wrapper>
  )
}

const get_random_color = () => css_colors[random(0, css_colors.length)]
const get_random_colors = () => [...Array(10 + 1).keys()].map(get_random_color) // add one extra color for the background

const css_colors = [
  'aliceblue',
  'antiquewhite',
  'aqua',
  'aquamarine',
  'azure',
  'beige',
  'bisque',
  'black',
  'blanchedalmond',
  'blue',
  'blueviolet',
  'brown',
  'burlywood',
  'cadetblue',
  'chartreuse',
  'chocolate',
  'coral',
  'cornflowerblue',
  'cornsilk',
  'crimson',
  'cyan',
  'darkblue',
  'darkcyan',
  'darkgoldenrod',
  'darkgray',
  'darkgreen',
  'darkkhaki',
  'darkmagenta',
  'darkolivegreen',
  'darkorange',
  'darkorchid',
  'darkred',
  'darksalmon',
  'darkseagreen',
  'darkslateblue',
  'darkslategray',
  'darkturquoise',
  'darkviolet',
  'deeppink',
  'deepskyblue',
  'dimgray',
  'dodgerblue',
  'firebrick',
  'floralwhite',
  'forestgreen',
  'fuchsia',
  'gainsboro',
  'ghostwhite',
  'goldenrod',
  'gold',
  'gray',
  'green',
  'greenyellow',
  'honeydew',
  'hotpink',
  'indianred',
  'indigo',
  'ivory',
  'khaki',
  'lavenderblush',
  'lavender',
  'lawngreen',
  'lemonchiffon',
  'lightblue',
  'lightcoral',
  'lightcyan',
  'lightgoldenrodyellow',
  'lightgray',
  'lightgreen',
  'lightpink',
  'lightsalmon',
  'lightseagreen',
  'lightskyblue',
  'lightslategray',
  'lightsteelblue',
  'lightyellow',
  'lime',
  'limegreen',
  'linen',
  'magenta',
  'maroon',
  'mediumaquamarine',
  'mediumblue',
  'mediumorchid',
  'mediumpurple',
  'mediumseagreen',
  'mediumslateblue',
  'mediumspringgreen',
  'mediumturquoise',
  'mediumvioletred',
  'midnightblue',
  'mintcream',
  'mistyrose',
  'moccasin',
  'navajowhite',
  'navy',
  'oldlace',
  'olive',
  'olivedrab',
  'orange',
  'orangered',
  'orchid',
  'palegoldenrod',
  'palegreen',
  'paleturquoise',
  'palevioletred',
  'papayawhip',
  'peachpuff',
  'peru',
  'pink',
  'plum',
  'powderblue',
  'purple',
  'rebeccapurple',
  'red',
  'rosybrown',
  'royalblue',
  'saddlebrown',
  'salmon',
  'sandybrown',
  'seagreen',
  'seashell',
  'sienna',
  'silver',
  'skyblue',
  'slateblue',
  'slategray',
  'snow',
  'springgreen',
  'steelblue',
  'tan',
  'teal',
  'thistle',
  'tomato',
  'turquoise',
  'violet',
  'wheat',
  'white',
  'whitesmoke',
  'yellow',
  'yellowgreen',
]

const Wrapper = Component.flex.ai_center.jc_center.article()
const Canvas = Component.w100p.h100p.canvas()
const Controls = Component.flex.absolute.t20.div()
const Button =
  Component.bg_white.w150.pv5.c_pointer.b_rad50.ba.sans.fs13.button()
