import { Component, Div } from '../utils/flags'

export const Block_89 = () => {
  return (
    <Wrapper
      style={{
        gridTemplateColumns: `repeat(${columns_amount}, 1fr)`,
        gridTemplateRows: `repeat(${columns_amount}, 1fr)`,
      }}
    >
      {[...Array(columns_amount * columns_amount).keys()].map((index) => (
        <Div key={index} flex ai_center jc_center w100p h100p>
          <Input type="radio" />
        </Div>
      ))}
    </Wrapper>
  )
}

const columns_amount = 20

const Wrapper = Component.c_pointer.grid.w100p.h100p.article()
const Input = Component.c_pointer.input()
