import { useState, useEffect } from 'react'
import { Component, Div } from '../utils/flags'
import noise from '../images/pepe-the-frog.jpeg'

export const Block_88 = () => {
  return (
    <Wrapper>
      {inputs.map((index) => (
        <Slice key={index} index={index} />
      ))}
    </Wrapper>
  )
}

const Slice = ({ index }) => {
  const [ref, set_ref] = useState(null)

  useEffect(() => {
    if (!ref) return
    const wrapper_height = ref.parentNode.getBoundingClientRect().height
    ref.scroll({ top: index * 20 + wrapper_height / 5 })
  }, [ref, index])

  return (
    <Div elemRef={set_ref} flex_wrap bg_white w100p ofy_scroll>
      <Div
        w100p
        style={{
          background: `center / cover url(${noise})`,
          height: '600%',
        }}
      ></Div>
    </Div>
  )
}

const inputs = [...Array(6).keys()]
const Wrapper = Component.grid.article()
