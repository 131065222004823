import { useState, useEffect } from 'react'
import { Component } from '../utils/flags'

// the `list` prop is an array either of strings, or of objects with keys `value` and `label`
export const Select = (props) => {
  const { list, value, set_value, hover_select, wrapper_id, ...style } = props
  const [is_open, set_is_open] = useState(false)
  const [wrapper, set_wrapper] = useState(null)
  const [hovered_option, set_hovered_option] = useState()

  const selected_option = list.find(
    (option) => (option.value || option) === value,
  )

  const options = hover_select
    ? list
    : list.filter((option) => (option.value || option) !== value)

  useEffect(() => {
    if (!wrapper_id) return
    const section = document.getElementById(`section-${wrapper_id}`)
    section.style.overflow = 'visible'
    const article = section.children[0]
    article.style.overflow = 'visible'
  }, [wrapper_id])

  // close the options select on click outside
  useEffect(() => {
    if (!wrapper) return
    const handle_click_outside = (event) => {
      if (wrapper.contains(event.target)) return
      set_is_open(false)
    }
    document.addEventListener('click', handle_click_outside)
    return () => document.removeEventListener('click', handle_click_outside)
  }, [wrapper])

  // close the options select on esc key press
  useEffect(() => {
    if (!wrapper) return
    const handle_keydown = (event) => {
      if (event.key !== 'Escape') return
      set_is_open(false)
    }
    document.addEventListener('keydown', handle_keydown)
    return () => document.removeEventListener('keydown', handle_keydown)
  }, [wrapper])

  return (
    <SelectWrapper
      id="select"
      elemRef={set_wrapper}
      onClick={() => set_is_open(!is_open)}
      {...style}
    >
      <Arrow />
      <Options>
        <SelectedOption style={{ height: '34px' }}>
          {selected_option.label || selected_option}
        </SelectedOption>
        <UnselectedOptions none={!is_open}>
          {options.map((option, index) => {
            // check if the option is an object with keys `value` & `label` or just a string
            // (in that case the value & the label are the same)
            const option_value = option.value || option
            const option_label = option.label || option
            return (
              <Option
                key={option_value}
                fs16={hovered_option === option_value}
                onMouseEnter={() => set_hovered_option(option_value)}
                onMouseOver={() => hover_select && set_value(option_value)}
                onClick={() => set_value(option_value)}
              >
                {option_label}
              </Option>
            )
          })}
        </UnselectedOptions>
      </Options>
    </SelectWrapper>
  )
}

const Arrow = () => (
  <Svg width={15} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 130">
    <path
      fill="none"
      stroke="black"
      strokeWidth={7}
      d="m115 42.26-50 50-50-50"
    />
  </Svg>
)

const SelectWrapper =
  Component.ba.relative.flex.ai_center.c_pointer.w100p.sans.ph10.ol_none.b_rad20.div()
const Options = Component.b_rad10.zi1.w100p.absolute.t0.l0.div()
const SelectedOption = Component.flex.ai_center.jc_center.div()
const UnselectedOptions =
  Component.max_h200.ofy_scroll.shadow_a_s.mt5.pv10.b_rad10.bg_white.div()
const Option = Component.w100p.lh21.flex.ai_center.jc_center.ellipsis.span()
const Svg = Component.absolute.r10.mt2.svg()
