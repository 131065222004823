import { Block_1 } from './Block_1'
import { Block_2 } from './Block_2'
import { Block_3 } from './Block_3'
import { Block_4 } from './Block_4'
import { Block_5 } from './Block_5'
import { Block_6 } from './Block_6'
import { Block_7 } from './Block_7'
import { Block_8 } from './Block_8'
import { Block_9 } from './Block_9'
import { Block_10 } from './Block_10'
import { Block_11 } from './Block_11'
import { Block_12 } from './Block_12'
import { Block_13 } from './Block_13'
import { Block_14 } from './Block_14'
import { Block_15 } from './Block_15'
import { Block_16 } from './Block_16'
import { Block_17 } from './Block_17'
import { Block_18 } from './Block_18'
import { Block_19 } from './Block_19'
import { Block_20 } from './Block_20'
import { Block_21 } from './Block_21'
import { Block_22 } from './Block_22'
import { Block_23 } from './Block_23'
import { Block_24 } from './Block_24'
import { Block_25 } from './Block_25'
import { Block_26 } from './Block_26'
import { Block_27 } from './Block_27'
import { Block_28 } from './Block_28'
import { Block_29 } from './Block_29'
import { Block_30 } from './Block_30'
import { Block_31 } from './Block_31'
import { Block_32 } from './Block_32'
import { Block_33 } from './Block_33'
import { Block_34 } from './Block_34'
import { Block_35 } from './Block_35'
import { Block_36 } from './Block_36'
import { Block_37 } from './Block_37'
import { Block_38 } from './Block_38'
import { Block_39 } from './Block_39'
import { Block_40 } from './Block_40'
import { Block_41 } from './Block_41'
import { Block_42 } from './Block_42'
import { Block_43 } from './Block_43'
import { Block_44 } from './Block_44'
import { Block_45 } from './Block_45'
import { Block_46 } from './Block_46'
import { Block_47 } from './Block_47'
import { Block_48 } from './Block_48'
import { Block_49 } from './Block_49'
import { Block_50 } from './Block_50'
import { Block_51 } from './Block_51'
import { Block_52 } from './Block_52'
import { Block_53 } from './Block_53'
import { Block_54 } from './Block_54'
import { Block_55 } from './Block_55'
import { Block_56 } from './Block_56'
import { Block_57 } from './Block_57'
import { Block_58 } from './Block_58'
import { Block_59 } from './Block_59'
import { Block_60 } from './Block_60'
import { Block_61 } from './Block_61'
import { Block_62 } from './Block_62'
import { Block_63 } from './Block_63'
import { Block_64 } from './Block_64'
import { Block_65 } from './Block_65'
import { Block_66 } from './Block_66'
import { Block_67 } from './Block_67'
import { Block_68 } from './Block_68'
import { Block_69 } from './Block_69'
import { Block_70 } from './Block_70'
import { Block_71 } from './Block_71'
import { Block_72 } from './Block_72'
import { Block_73 } from './Block_73'
import { Block_74 } from './Block_74'
import { Block_75 } from './Block_75'
import { Block_76 } from './Block_76'
import { Block_77 } from './Block_77'
import { Block_78 } from './Block_78'
import { Block_79 } from './Block_79'
import { Block_80 } from './Block_80'
import { Block_81 } from './Block_81'
import { Block_82 } from './Block_82'
import { Block_83 } from './Block_83'
import { Block_84 } from './Block_84'
import { Block_85 } from './Block_85'
import { Block_86 } from './Block_86'
import { Block_87 } from './Block_87'
import { Block_88 } from './Block_88'
import { Block_89 } from './Block_89'
import { Block_90 } from './Block_90'
import { Block_91 } from './Block_91'

export const blocks_list = [
  Block_1,
  Block_2,
  Block_3,
  Block_4,
  Block_5,
  Block_6,
  Block_7,
  Block_8,
  Block_9,
  Block_10,
  Block_11,
  Block_12,
  Block_13,
  Block_14,
  Block_15,
  Block_16,
  Block_17,
  Block_18,
  Block_19,
  Block_20,
  Block_21,
  Block_22,
  Block_23,
  Block_24,
  Block_25,
  Block_26,
  Block_27,
  Block_28,
  Block_29,
  Block_30,
  Block_31,
  Block_32,
  Block_33,
  Block_34,
  Block_35,
  Block_36,
  Block_37,
  Block_38,
  Block_39,
  Block_40,
  Block_41,
  Block_42,
  Block_43,
  Block_44,
  Block_45,
  Block_46,
  Block_47,
  Block_48,
  Block_49,
  Block_50,
  Block_51,
  Block_52,
  Block_53,
  Block_54,
  Block_55,
  Block_56,
  Block_57,
  Block_58,
  Block_59,
  Block_60,
  Block_61,
  Block_62,
  Block_63,
  Block_64,
  Block_65,
  Block_66,
  Block_67,
  Block_68,
  Block_69,
  Block_70,
  Block_71,
  Block_72,
  Block_73,
  Block_74,
  Block_75,
  Block_76,
  Block_77,
  Block_78,
  Block_79,
  Block_80,
  Block_81,
  Block_82,
  Block_83,
  Block_84,
  Block_85,
  Block_86,
  Block_87,
  Block_88,
  Block_89,
  Block_90,
  Block_91,
]
