import { useState } from 'react'
import { Component } from '../utils/flags'

export const Block_87 = () => {
  const [ref, set_ref] = useState(null)
  const [points, set_points] = useState(base_points)
  const [path_thickness, set_path_thickness] = useState(50)

  const handle_mousemove = (event) => {
    event = event.type === 'touchmove' ? event.touches[0] : event
    const { offsetLeft, offsetTop } = ref.offsetParent
    const x = event.clientX - offsetLeft
    const y = event.clientY - (offsetTop - window.pageYOffset)
    const last_point = points.at(-1)
    if (last_point) {
      const xx = x - last_point.x
      const yy = y - last_point.y
      const distance = Math.hypot(xx, yy)
      if (distance > 10) {
        if (points.length === 45) points.shift()
        set_points([...points, { x, y }])
      }
    } else {
      set_points([{ x, y }])
    }
  }

  const d = `${points.map(
    (point, index) => `${index ? 'L' : 'M'} ${point.x}, ${point.y}`,
  )}`

  return (
    <Wrapper style={{ background: 'green' }} elemRef={set_ref}>
      <Svg
        onMouseMove={handle_mousemove}
        onTouchMove={handle_mousemove}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path id="drawn-path" d={d} />
        </defs>

        <Path
          fill="none"
          stroke="peachpuff"
          strokeWidth={path_thickness}
          strokeLinecap="round"
          strokeLinejoin="round"
          d={d}
        />

        <Text textAnchor="start" fill="goldenrod">
          <textPath alignmentBaseline="middle" xlinkHref="#drawn-path">
            following following
          </textPath>
        </Text>
      </Svg>

      <Controls>
        <input
          onInput={(event) => set_path_thickness(event.target.value)}
          style={{ transform: 'scale(0.6)' }}
          type="range"
          min="0"
          max="100"
        />
      </Controls>
    </Wrapper>
  )
}

const base_points = [
  { x: 156, y: 299 },
  { x: 147, y: 290 },
  { x: 139, y: 279 },
  { x: 134, y: 269 },
  { x: 133, y: 258 },
  { x: 133, y: 245 },
  { x: 137, y: 233 },
  { x: 141, y: 222 },
  { x: 147, y: 211 },
  { x: 153, y: 198 },
  { x: 160, y: 187 },
  { x: 168, y: 176 },
  { x: 178, y: 165 },
  { x: 186, y: 157 },
  { x: 198, y: 146 },
  { x: 208, y: 136 },
  { x: 216, y: 125 },
  { x: 221, y: 116 },
  { x: 223, y: 103 },
  { x: 218, y: 92 },
  { x: 206, y: 82 },
  { x: 190, y: 74 },
  { x: 178, y: 71 },
  { x: 161, y: 67 },
  { x: 144, y: 64 },
  { x: 130, y: 63 },
  { x: 118, y: 61 },
  { x: 103, y: 61 },
  { x: 91, y: 62 },
  { x: 82, y: 67 },
  { x: 75, y: 75 },
  { x: 69, y: 84 },
  { x: 66, y: 95 },
  { x: 64, y: 106 },
  { x: 64, y: 117 },
  { x: 69, y: 128 },
  { x: 78, y: 140 },
  { x: 88, y: 146 },
  { x: 98, y: 151 },
  { x: 108, y: 155 },
  { x: 119, y: 158 },
  { x: 131, y: 159 },
  { x: 146, y: 161 },
  { x: 159, y: 163 },
  { x: 169, y: 165 },
]

const Wrapper = Component.flex.jc_center.ai_center.article()
const Svg = Component.t0.l0.absolute.w100p.h100p.svg()
const Text = Component.fs40.ls10.text()
const Path = Component.path()
const Controls = Component.absolute.b10.div()
